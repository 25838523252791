import {DisclosureButton} from "../utils/disclosure-button";

function scrollSpy() {
  const onIntersect = (entries) => {
    entries.forEach((entry) => {
      const targetId = entry.target.id; // Get the ID of the intersected element

      // Find the corresponding link (no error handling for missing links)
      const targetLink = document.querySelector(`[href="#${targetId}"]`);
      if (!targetLink) return; // Skip if no link is found

      // Add or remove the 'is-active' class based on intersection
      if (entry.isIntersecting || entry.isVisible) {
        targetLink.classList.add('is-active');
      } else {
        targetLink.classList.remove('is-active');
      }
    });
  };

  var headerHeight =  document.querySelector('.c-main-header').offsetHeight * (-1) + 'px';

  const observer = new IntersectionObserver(onIntersect, {
    rootMargin: headerHeight + ' 0px -70% 0px', // Adjust as needed
  });

  // Select paragraphs excluding ".sommaire" and ".hautdepage"
  document.querySelectorAll('.paragraphe:not(.sommaire,.hautdepage)')
    .forEach((paragraph) => {
      observer.observe(paragraph);
    });
}

function summaryMobile() {
  const summaryTitle = document.querySelector('.js-summary-button');

  if (summaryTitle) {
    new DisclosureButton(summaryTitle, 0, true, 400, false);
  }
}

// Check if a single ".TPL_SOMMAIREPAGE" element exists
if (document.querySelector('.TPL_SOMMAIREPAGE') && document.querySelectorAll('.TPL_SOMMAIREPAGE').length === 1) {
  scrollSpy();
}

if (window.innerWidth < 1024) {
  summaryMobile();
}
