// import your javascript libraries at the top
import { DisclosureButton } from "../utils/disclosure-button";
import {Modal} from "../utils/modals";

// Custom JavaScript
const AutoFlashInfo = () => {
    const triggerButton = document.querySelector(".js-flash-info-btn");
    if (triggerButton === null) return;
    new DisclosureButton(triggerButton, 0, true, 400, false);
};

window.addEventListener(
    "DOMContentLoaded",
    AutoFlashInfo,
    false
);
