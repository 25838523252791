import {
  computePosition, flip, shift, offset, arrow,
} from '@floating-ui/dom';

export class Tooltip {
  constructor(element) {
    this.container = element;
    this.button = element.querySelector('.js-tooltip-button');
    this.tooltip = element.querySelector('[role=tooltip]');
    this.arrow = document.createElement('span');
    this.tooltipPosition = this.getTooltipPosition();
    this.globalEscapeBound = this.globalEscape.bind(this);
  }

  init() {
    this.createArrowElement();
    this.initialiseClassList(this.tooltipPosition);
    this.bindEvents();
  }

  createArrowElement() {
    this.arrow.classList.add('js-arrow', 'c-tooltip__arrow');
    this.tooltip.append(this.arrow);
  }

  // Basic actions
  openTooltip() {
    this.attachGlobalListener();
    this.showTooltip();
    this.setTooltipPlacement();
  }

  closeTooltip() {
    this.removeGlobalListener();
    this.hideTooltip();
  }

  // Binding event listeners
  bindEvents() {
    // Events that trigger openTooltip()
    // Open on mouse hover
    this.container.addEventListener('mouseenter', this.openTooltip.bind(this));
    // Open when a touch is detected
    this.container.addEventListener('touchstart', this.openTooltip.bind(this));
    // Open when the button gets focus
    this.button.addEventListener('focus', this.openTooltip.bind(this));

    // Events that trigger closeTooltip()
    // Close when the mouse cursor leaves the button or tooltip area
    this.container.addEventListener('mouseleave', this.closeTooltip.bind(this));
    // Close when the buttons loses focus
    this.button.addEventListener('blur', this.closeTooltip.bind(this));
  }

  attachGlobalListener() {
    document.addEventListener('keydown', this.globalEscapeBound);
  }

  removeGlobalListener() {
    document.removeEventListener('keydown', this.globalEscapeBound);
  }

  globalEscape(event) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      this.closeTooltip();
    }
  }

  // Show or hide the tooltip
  showTooltip() {
    this.container.classList.add('c-tooltip--visible');
    this.tooltip.classList.remove('u-hidden');
  }

  hideTooltip() {
    this.container.classList.remove('c-tooltip--visible');
    this.tooltip.classList.add('u-hidden');
  }

  // Set the default classes for tooltips based on this.getTooltipPosition() on init
  initialiseClassList(position) {
    switch (position) {
      case 'top':
        this.container.classList.add('c-tooltip--top');
        break;
      case 'left':
        this.container.classList.add('c-tooltip--left');
        break;
      case 'right':
        this.container.classList.add('c-tooltip--right');
        break;

      default:
        this.container.classList.remove('c-tooltip--top', 'c-tooltip--left', 'c-tooltip--right');
        break;
    }
  }

  setTooltipPlacement() {
    computePosition(this.button, this.tooltip, {
      placement: this.tooltipPosition,
      middleware: [
        offset(6),
        flip(),
        shift({ padding: 5 }),
        arrow({ element: this.arrow }),
      ],
    }).then(({
               x, y, placement, middlewareData,
             }) => {
      // Placement dynamique de la tooltip
      Object.assign(this.tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      // On ajuste la classe avec le modificateur suite aux calculs du plugin (Par ex: Un "bottom" peut devenir un "top" en fonction de l'espace disponible)
      this.initialiseClassList(placement);

      // On r�cup�re les donn�es pour placer la fl�che
      const { x: arrowX, y: arrowY } = middlewareData.arrow;

      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]];

      // On place la fl�che
      Object.assign(this.arrow.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: '-4px',
      });
    });

  }

  // Get the desired default position for the tooltip (defaults to 'bottom')
  getTooltipPosition() {
    let attribute = this.container.getAttribute('data-tooltip-position');

    if (attribute !== 'top' && attribute !== 'left' && attribute !== 'right') {
      attribute = 'bottom';
    }

    return attribute;
  }
}
