import { DisclosureButton } from '../utils/disclosure-button';
document.addEventListener('DOMContentLoaded', () => {
  //Gestion du style de paragraphe
  const accordions = document.querySelectorAll('.PRS_ACCORDION .paragraphe__inner:not(.js-ressources-paragraphe)');

  if (accordions) {
    accordions.forEach((accordion) => {
      const children = Array.from(accordion.children);
      let groupCounter = 0;
      let currentGroup = null;
      let parendId = accordion.parentNode.id;

      children.forEach((child) => {
        if (child.tagName === 'H3') {
          groupCounter++;
          const groupId = String(groupCounter).padStart(2, '0');
          const parID = parendId + '-' + groupId;

          const button = document.createElement('button');
          button.className = 'c-accordion__button js-accordion';
          button.setAttribute('aria-expanded', 'false');
          button.setAttribute('aria-controls', `accordion-content-${parID}`);
          button.innerHTML = `${child.textContent} <i class="c-accordion__icon o-icon o-icon--arrow-down" aria-hidden="true"></i>`;
          child.textContent = '';
          child.classList.add('c-accordion__heading');
          child.setAttribute('id', `accordion-header-${parID}`);
          child.appendChild(button);

          // Cr�ation d'une nouvelle div pour le contenu
          currentGroup = document.createElement('div');
          currentGroup.setAttribute('role', 'region');
          currentGroup.setAttribute('id', `accordion-content-${parID}`);
          currentGroup.setAttribute('aria-labelledby', `accordion-header-${parID}`);
          currentGroup.className = 'c-accordion__content';
          currentGroup.setAttribute('aria-hidden', 'true');

          // Ins�rer la nouvelle div juste apr�s le H3
          accordion.insertBefore(currentGroup, child.nextSibling);
        } else if (currentGroup) {
          // Si un groupe courant existe, y ajouter l'�l�ment
          currentGroup.appendChild(child);
        }
      });
    });
  }

  const accordeons = document.querySelectorAll('.js-accordion');
  accordeons.forEach((accordeon) => {
    new DisclosureButton(accordeon, 0, true, 400, false);
  });
});
